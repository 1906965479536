<mat-sidenav-container class="example-container new-line" autosize>
  <mat-sidenav #sidenav class="example-sidenav" mode="side" opened="true" (mouseenter)="mouseenter()"
    (mouseleave)="mouseleave()">
    <mat-nav-list>
      <div class="name text-center">
      </div>
      <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent mb-2 mt-4" [ngClass]="[isDashboard ? 'bordermenu'  : '']" [routerLink]="['/dashboard']">
        <i class="fas fa-home-lg-alt" [ngClass]="[isDashboard ? 'comp' : '']"></i>
        <span class="full-width mt-1" *ngIf="isExpanded || isShowing" [ngClass]="[isDashboard ? 'comp' : '']" style="    margin-left: 0.520833vw !important;
       top: 10.872395833333334vw;
        left: 6.4453125vw;
        width: 8.6640625vw;
        height: 1.4322916666666667vw;
        text-align: left;
        letter-spacing: 0px;
        opacity: 1;
        font-family: Roboto !important;
    font-size:0.9375vw;
    font-weight: 500;">Dashboard</span>
        <!-- <i class="fas fa-caret-left fa-3x arrow-icon2 d-flex align-items-end"*ngIf="isArrow1"></i> -->
      </mat-list-item>
      <mat-list-item (click)="showSubmenu = !showSubmenu"  class="parent mb-2 " [ngClass]="[isSummaryReport ? 'bordermenu'  : '']" (click)="getSummary()">
        <i class="fas fa-tasks" [ngClass]="[isSummaryReport ? 'comp' : '']"></i>
        <span class="full-width " *ngIf="isExpanded || isShowing " [ngClass]="[isSummaryReport ? 'comp'  : '']"  style="    margin-left: 0.520833vw !important;
        top: 10.872395833333334vw;
        left: 6.4453125vw;
        width: 8.6640625vw;
        height: 1.4322916666666667vw;
        text-align: left;
        letter-spacing: 0px;
        opacity: 1;
        font-family: Roboto !important;
    font-size:0.9375vw;
    font-weight: 500;"
        >Summary Report</span>
      </mat-list-item>
      <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent mb-2" [ngClass]="[isDetailsReport ? 'bordermenu'  : '']" [routerLink]="['/detail']">
        <i class="fas fa-books" [ngClass]="[isDetailsReport ? 'comp' : '']"></i>
   
        <span class="full-width" *ngIf="isExpanded || isShowing" [ngClass]="[isDetailsReport ? 'comp' : '']" style="    margin-left: 0.520833vw !important;
        top: 10.872395833333334vw;
        left: 6.4453125vw;
        width: 8.6640625vw;
        height: 1.4322916666666667vw;
        text-align: left;
        letter-spacing: 0px;
        opacity: 1;
        font-family: Roboto !important;
    font-size:0.9375vw;
    font-weight: 500;">Detail Report</span>
      </mat-list-item>
      <!-- <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent mb-2">
        <i class="fas fa-box"></i>
        <span class="full-width" *ngIf="isExpanded || isShowing">Products</span>
      </mat-list-item> -->
    </mat-nav-list>
    <mat-nav-list> </mat-nav-list>
  </mat-sidenav>
</mat-sidenav-container>