import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.css']
})
export class AuthenticationComponent implements OnInit {
  switchValue: any = true;
  direction: any = 'ltr';
  param = { value: 'world' };
  dir=sessionStorage.getItem("direction");


  constructor(private router: Router) {
    
  }
  ngOnInit(): void {     
    
  }
 
  
 
  
}
