
<div class="cup-container" *ngIf="IsLoader">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        style="display: none;">
        <symbol id="wave">
            <path
                d="M420,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C514,6.5,518,4.7,528.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H420z">
            </path>
            <path
                d="M420,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C326,6.5,322,4.7,311.5,2.7C304.3,1.4,293.6-0.1,280,0c0,0,0,0,0,0v20H420z">
            </path>
            <path
                d="M140,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C234,6.5,238,4.7,248.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H140z">
            </path>
            <path
                d="M140,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C46,6.5,42,4.7,31.5,2.7C24.3,1.4,13.6-0.1,0,0c0,0,0,0,0,0l0,20H140z">
            </path>
        </symbol>
    </svg>
    <div class="cup">
        <div id="coffee" class="" [ngClass]="[ispractice ? 'coffee' : 'coffee coffee1']">
            <svg viewBox="0 0 560 20" class="coffee-wave coffee-wave__back">
                <use xlink:href="#wave"></use>
            </svg>
            <svg viewBox="0 0 560 20" class="coffee-wave coffee-wave__front">
                <use xlink:href="#wave"></use>
            </svg>
            
        </div>
    </div>
    <div class="cup-icon"></div>
    <div aria-hidden="true" class="loading-text">Preparing publication...</div>
</div>
<!-- <button (click)="Check()">save</button> -->

<div *ngIf="IsTNQiFrame">
    <iframe title="CQA_Dashboard - TNQ_QA" style="width: 80vw; height: 40vw;" src="https://app.powerbi.com/view?r=eyJrIjoiODc5YWVkNDEtNzE5NC00YzU1LTk3ZjktOGE3MmVjY2ZkNTFkIiwidCI6ImU1NTBkMzM0LTUwYTItNDY4Yy05YzZiLTlhZjA4ZTZkNGEzNCJ9" frameborder="0" allowFullScreen="true"></iframe>
</div>

<div *ngIf="IsAptaraFrame">
    <iframe title="CQA_Dashboard - Aptara_QA" style="width: 80vw; height: 40vw;" src="https://app.powerbi.com/view?r=eyJrIjoiM2YwMTRhNTUtNTk1Ni00ZGJmLWE3MzItM2JmYjYwNjMxZDY0IiwidCI6ImU1NTBkMzM0LTUwYTItNDY4Yy05YzZiLTlhZjA4ZTZkNGEzNCJ9" frameborder="0" allowFullScreen="true"></iframe>
</div>

<div *ngIf="IsMPSiFrame">
    <iframe title="CQA_Dashboard - MPS_QA" style="width: 80vw; height: 40vw;" src="https://app.powerbi.com/view?r=eyJrIjoiN2Q3OTkzM2UtZGRmYy00YmVlLWFmNTktMDI3ZDRjMWFmMjE1IiwidCI6ImU1NTBkMzM0LTUwYTItNDY4Yy05YzZiLTlhZjA4ZTZkNGEzNCJ9" frameborder="0" allowFullScreen="true"></iframe>
</div>

<div *ngIf="IsStriveiFrame">
    <iframe title="CQA_Dashboard - Straive_QA" style="width: 80vw; height: 40vw;" src="https://app.powerbi.com/view?r=eyJrIjoiNzQ0NmMzNTMtMDE0Zi00MTJjLWIwZmUtZGEwOTYzM2E1MzQ2IiwidCI6ImU1NTBkMzM0LTUwYTItNDY4Yy05YzZiLTlhZjA4ZTZkNGEzNCJ9" frameborder="0" allowFullScreen="true"></iframe>
</div>

 <div *ngIf="Is3WPiFrame">
   <iframe title="CQA_Dashboard - 3WplusP"style="width: 80vw; height: 40vw;" src="https://app.powerbi.com/view?r=eyJrIjoiNjQ3NGQxMTktNTMyNS00MTRkLWJiMmItM2IyYzkzZGIyNzhiIiwidCI6ImU1NTBkMzM0LTUwYTItNDY4Yy05YzZiLTlhZjA4ZTZkNGEzNCJ9" frameborder="0" allowFullScreen="true"></iframe>
</div> 

