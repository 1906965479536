import { Component, OnInit, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenubarComponent } from '../menubar/menubar.component';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.css']
})
export class MasterComponent implements OnInit, OnChanges {
  @ViewChild(MenubarComponent ) menuBar: MenubarComponent ;
  public isClassMenu:boolean;
  ishide:boolean;
  public isHomeMenu:boolean;
  isdisplay:any = sessionStorage.getItem('viewmenu');
  direction: any =  sessionStorage.getItem('direction');
  constructor(private router: Router) { }

  ngOnChanges(): void {
    
    let currentRoute = this.router;
    let currentUrl = currentRoute.url;
    this.ishide = ['/learntest'].findIndex(str => currentUrl.includes(str)) == 0;
    // alert(this.ishide)
    // this.isdisplay = sessionStorage.getItem('viewmenu');
   
  }
  onActivate(){ 
    let currentRoute = this.router;
    let currentUrl = currentRoute.url;
    this.ishide = ['/learntest'].findIndex(str => currentUrl.includes(str)) == 0;
    // alert(this.ishide)
      // this.isdisplay = sessionStorage.getItem('viewmenu');
    //  this.isdisplay = 'false';
  
    // if(this.menuBar!=undefined)
    //   // this.menuBar.checkCurrentLocation();
  }

  ngOnInit(): void {
    let currentRoute = this.router;
    let currentUrl = currentRoute.url;
    this.ishide = ['/learntest'].findIndex(str => currentUrl.includes(str)) == 0;
    // alert(this.ishide)
    // this.isdisplay = sessionStorage.getItem('viewmenu');
   
  }



}
