import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesComponent } from './pages.component';
import { AuthenticationModule } from './authentication/authentication.module';
// import { HomeModule } from './home/home.module';
import { PagesRoutingModule } from './pages-routing.module';
import { CoreModule } from '../core/core.module';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CheckBoxModule, ButtonAllModule } from '@syncfusion/ej2-angular-buttons';

//OTHER IMPORTS
// import { CustomProgressComponent } from './../shared/custom-progress/custom-progress.component';
import { ProgressBarAllModule } from '@syncfusion/ej2-angular-progressbar';
import { Routes, RouterModule } from '@angular/router';
//For Sceduler
import { TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { DropDownListAllModule, DropDownListModule, MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { MaskedTextBoxModule, UploaderAllModule } from '@syncfusion/ej2-angular-inputs';
import { ToolbarAllModule, ContextMenuAllModule } from '@syncfusion/ej2-angular-navigations';
import { CheckBoxAllModule } from '@syncfusion/ej2-angular-buttons';
import { DatePickerAllModule, TimePickerAllModule, DateTimePickerAllModule } from '@syncfusion/ej2-angular-calendars';
import { NumericTextBoxAllModule, TextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { ScheduleAllModule, RecurrenceEditorAllModule } from '@syncfusion/ej2-angular-schedule';


import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import {MatExpansionModule} from '@angular/material/expansion';

import { DashboardModule } from './dashboard/dashboard.module';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  imports: [CommonModule,RouterModule, PagesRoutingModule,
    AuthenticationModule, CoreModule, FormsModule, ProgressBarAllModule,
    NgSelectModule, TextBoxAllModule, CheckBoxModule, ButtonAllModule,DashboardModule    
    // For Scheduler
    ,TreeViewModule
    ,DropDownListAllModule
    ,DropDownListModule
    ,MultiSelectAllModule
    ,MaskedTextBoxModule
    ,UploaderAllModule
    ,ToolbarAllModule
    ,ContextMenuAllModule
    ,ButtonAllModule
    ,CheckBoxAllModule
    ,DatePickerAllModule
    ,TimePickerAllModule
    ,DateTimePickerAllModule
    ,NumericTextBoxAllModule
    ,TextBoxAllModule
    ,ScheduleAllModule
    ,RecurrenceEditorAllModule
    ,PdfViewerModule
    ,MatExpansionModule,
    TabsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    PagesComponent,ConfirmationDialogComponent 
    
  ],
  providers:[ConfirmationDialogService]
})
export class PagesModule { }

//platformBrowserDynamic().bootstrapModule(PagesModule);
