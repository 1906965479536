import { Injectable, InjectionToken } from '@angular/core';

export const LOGIN_SERVICE_URI = new InjectionToken<string>('LOGIN_SERVICE_URI');


export function ConfigFactory(configService: ConfigService, property: string) {
  return configService.loadConfigJSON("./assets/config.json")[property];
}
@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor() { }

  loadConfigJSON(filePath){
    const json = this.loadTextFileAjaxSync(filePath, "application/json");
    return JSON.parse(json);
  }

  loadTextFileAjaxSync(filePath, mimeType) {
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.open("GET", filePath, false);
    if (mimeType != null) {
      if (xmlhttp.overrideMimeType) {
        xmlhttp.overrideMimeType(mimeType);
      }
    }
    xmlhttp.send();
    if (xmlhttp.status == 200) {
      return xmlhttp.responseText;
    } else {
      return null
    }
  }
}
