import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {LOGIN_SERVICE_URI} from './config.service';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public loginserviceuri:any;
  constructor(
    private httpClient: HttpClient,
    @Inject(LOGIN_SERVICE_URI) loginserviceuri: string
    
    
    ) {
      this.loginserviceuri=loginserviceuri;
     }

  public getSummaryReport(VendorId:any): Observable<any> {
    let BaseUrl = this.loginserviceuri;
    let url = `${BaseUrl}VendorId/SummaryReport/${VendorId}`
    console.log(url)  
    return this.httpClient.get(url);
  }

  public getFilterSummaryReport(VendorId:any,analysedstatus:any): Observable<any> {
    let BaseUrl = this.loginserviceuri;
    let url = `${BaseUrl}VendorId/filter/SummaryReport/${VendorId}/${analysedstatus}`
    console.log(url)  
    return this.httpClient.get(url);
  }


  public updateAdditionalremark(requestBody: object): Observable<any> {
    let BaseUrl = this.loginserviceuri;
    let url = `${BaseUrl}Update/AdditionalRemark`
    console.log(url)
    return this.httpClient.post(url, requestBody);
  }

  public getErrorReport(VendorId:any): Observable<any> {
    let BaseUrl = this.loginserviceuri;
    let url = `${BaseUrl}VendorId/DetailErrorReport?vendorId=${VendorId}`
    console.log(url)
    return this.httpClient.get(url);
  }

  public getLogin(requestBody: object): Observable<any> {
    let BaseUrl = this.loginserviceuri;
    let url = `${BaseUrl}Login/Vendor`
    console.log(url)
    return this.httpClient.post(url,requestBody);
  }

  public UpdateSummrayAnalysis(requestBody: object): Observable<any> {
    let BaseUrl = this.loginserviceuri;
    let url = `${BaseUrl}JobId/bySummaryAnalysis`
    console.log(url)
    return this.httpClient.post(url, requestBody);
  }
  
 //get Vedor rejected article error
 public getVendorErrorData(JobId:any):Observable<any>{
  let BaseUrl = this.loginserviceuri;
  let url = `${BaseUrl}Vendor/ErrorDetailsby/${JobId}`
  console.log(url)
  return this.httpClient.get(url);

 }

 public updateStatus(requestBody:any):Observable<any>{
  let BaseUrl = this.loginserviceuri;
  let url = `${BaseUrl}VendorId/SummaryReport/UpdateIsAnalyzed`
  console.log(url)
  return this.httpClient.post(url,requestBody);
 }

public GetPdfDetails(JobId:any):Observable<any>{
  let BaseUrl=this.loginserviceuri;
  let url=`${BaseUrl}Pdf/Get/DetailsForVendor/${JobId}`
  console.log(url)
  return this.httpClient.get(url);
}

public GetBaseUrl():Observable<any>{
  return this.loginserviceuri;
}

}
