<!-- <nav class="navbar p-0 navbar-expand  border-bottom box-shadow navbar-custom">
  <div class="col-12 row">
    <div class=" " style="align-items: center;display: flex;    width: 21.9270833333333333vw;
        background-color: #FFFFFF;">
      <img src="../../../assets/Images/Icodex-logo.jpg" id="logo" [routerLink]="['/login']">
    </div>
    <div class="col-4" style="margin-left: 20.833333333333332vw;display: flex;
        align-items: center;">
      <h5 class="ml-5 " style="font-size: 1.6666666666666667vw !important;
        text-align: left;
        font: normal normal bold 1.6666666666666667vw/1.3671875vw Roboto;
        letter-spacing: 0px;
        color: #2A2B2F;
        width: 26.692708333333332vw;
        height: 2.4739583333333335vw;
        opacity: 1; " id="head">Customer Quality Assurance
      </h5>
    </div>
    <div class="col"></div>
    <div class="col-2">
      <nav class="navbar navbar-dark  navbar-expand-sm">

        <div class="collapse navbar-collapse" id="navbar-list-4">
          <ul class="navbar-nav">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                style="margin-left: 6.82917vw;margin-top: -0.8854166666666666vw;">
                <i class="fa fa-user bottomonly">
                  <p class="vendorName">{{this.vendorName}}</p>
                </i>
              </a>
              <div class="dropdown-menu menu1" aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item dlist" href="#">Dashboard</a>
                <a class="dropdown-item dlist" href="#">Edit Profile</a>
                <a class="dropdown-item dlist" href="#">Log Out</a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>

  </div>


</nav> -->

<div class="row" style="height: 5.146875vw;border-bottom:1px solid #F5F6F8;">
  <div class=" " style="align-items: center;display: flex;width: 13.315vw;justify-content: center;
        background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 0px 1.3020833333333333vw #00000014;
opacity: 1;">
      <img src="../../../assets/Images/Icodex-logo.jpg" id="logo" [routerLink]="['/login']">
    </div>

    <div class="col" style="display: flex;
    align-items: center;margin-left: 31.8vw;font: normal normal bold 1.6666666666666667vw/1.09375vw Roboto;
letter-spacing: 0px;
color: #2A2B2F;
opacity: 1;
padding: 0px;
margin-right: 10.8vw;">
      Customer Quality Assurance
    </div>

    
    <div class="col" style="display: flex;
    align-items: center;font: normal normal bold 1.1458333333333333vw/1.09375vw Roboto;
letter-spacing: 0px;
color: #4D7CFE;justify-content: center;">{{this.vendorName}}


      <i class="fa fa-user" style=" margin-left: 1.9270833333333333vw;
      margin-right: 2.9416666666666667vw;
      font-size: 3.1770833333333335vw;" aria-hidden="true"></i>
      <i class="fa fa-caret-down dropcss" style="width: 0.6864583333333333vw;color:#4D7CFE ;" aria-hidden="true" href="#" id="navbarDropdownMenuLink" role="button"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
      <div class="dropdown-menu menu1" style="font-family: 'Roboto';
      font-size: 0.8333333333333334vw;
      font-weight: 500;" aria-labelledby="navbarDropdownMenuLink">
        <a class="dropdown-item dlist" href="#">Dashboard</a>
        <a class="dropdown-item dlist" href="#">Edit Profile</a>
        <a class="dropdown-item dlist" href="#">Log Out</a>
      </div>
    </div>
    
    
</div>