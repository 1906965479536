
     <div class="container p-0">
    <ul class=" p-0">
        <h5 class="mb-5 custom-workflow" >Workflow Steps</h5>        
        <div class="row">
            <div class="col-2">
                <i class="far fa-check-circle fa-2x" [ngClass]="[isInatke ? 'comp' : '',isCompletenesCheck ? 'comp' : '',  isAuthorQuires ? 'comp' : '', isFinalReview ? 'comp' : '']"></i>
            </div>
            <div class="col mt-2">
                <span class="custom-h3" [ngClass]="[isInatke ? 'comp' : '',isCompletenesCheck ? 'comp' : '',  isAuthorQuires ? 'comp' : '', isFinalReview ? 'comp' : '']">Intake</span>
            </div>
        </div>
        <i class="fas fa-horizontal-rule fa-2x" [ngClass]="[isCompletenesCheck ? 'comp' : '',  isAuthorQuires ? 'comp' : '', isFinalReview ? 'comp' : '']"></i>
        <div class="row">
            <div class="col-2">
                <i class="far fa-check-circle fa-2x" [ngClass]="[isCompletenesCheck ? 'comp' : '',  isAuthorQuires ? 'comp' : '', isFinalReview ? 'comp' : '']"></i>
            </div>
            <div class="col mt-2">
                <span class="custom-h3" [ngClass]="[isCompletenesCheck ? 'comp' : '',  isAuthorQuires ? 'comp' : '', isFinalReview ? 'comp' : '']">Article Pre-screening</span>
            </div>
        </div>
        <i class="fas fa-horizontal-rule fa-2x" [ngClass]="[isAuthorQuires ? 'comp' : '', isFinalReview ? 'comp' : '']"></i>
        <div class="row">
            <div class="col-2">
                <i class="far fa-check-circle fa-2x" [ngClass]="[  isAuthorQuires ? 'comp' : '', isFinalReview ? 'comp' : '']"></i>
            </div>
            <div class="col mt-2">
                <span class="custom-h3"[ngClass]="[ isAuthorQuires ? 'comp' : '', isFinalReview ? 'comp' : '']">Quality Check</span>
            </div>
        </div>
        <i class="fas fa-horizontal-rule fa-2x"[ngClass]="[isFinalReview ? 'comp' : '']"></i>
        <div class="row">
            <div class="col-2">
                <i class="far fa-check-circle fa-2x" [ngClass]="[ isFinalReview ? 'comp' : '']"></i>
            </div>
            <div class="col mt-2">
                <span class="custom-h3" [ngClass]="[isFinalReview ? 'comp' : '']">Final Review</span>
            </div>
        </div>
        <!-- <i class="fas fa-horizontal-rule fa-2x"></i> -->
        <!-- <div class="row mt-5">
            <div class="col-2" >
                <i class="far fa-check-circle fa-2x" [ngClass]="[isFinal ? 'comp' : '']"></i>
            </div>
            <div class="col mt-2">
                <span class="custom-h3" [ngClass]="[isFinal ? 'comp' : '']">Completed</span>
            </div>
        </div> -->
    </ul>
</div> 


<div class="col">
    
</div>

