import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { MasterComponent } from './master/master.component';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports: [],
})

export class CoreRoutingModule { }
