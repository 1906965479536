<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">{{'Buttons.DeleteEvent' | translate }}</h4>
        <button style="display: none;" type="button" class="close" aria-label="Close" (click)="dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        {{ message }}
    </div>
    <div class="modal-footer">
        <button type="button" class="btn-default btn " (click)="accept()">{{'Buttons.Ok' | translate }}</button>
        <button type="button" class="btn-default btn " (click)="decline()">{{'Buttons.Cancel' | translate }}</button>       
    </div>
</div>