<div class="main">
    <div class="row" style="height: 5.146875vw;border-bottom:0.10416666666666667vw solid #F5F6F8;width: 100vw;">
        <div class="div" style="display: flex;">
            <div class=" " style="align-items: center;display: flex;width: 14.739583333333334vw;
              background-color: #FFFFFF;justify-content: center;height: 5.146354166666667vw;border-bottom:0.10416666666666667vw solid #F5F6F8">
            <img src="../../../assets/Images/Icodex-logo.jpg" id="logo" [routerLink]="['/login']">
        </div>
            <div class="div heading1">
                Customer Quality Assurance
            </div>
        </div>
    </div>

    <div class="row card">
        <div class="div">
        <div class="row welcome">Reset Password</div>
        <div class="row signin">Reset Password with Customer Quality Assurance</div>

        <div class="row resetinst">Enter your Email and instructions will be sent to you!</div>
        

        <div class="row" style="margin-left:0.9895833333333334vw;margin-top: 1.3020833333333333vw;">
            <div class="text-title">Email</div>
            <div class="input-text"><input type="text" value="" placeholder="Enter email" formControlName="Username" class="form-control"></div>
        </div>

        <div class="col d-flex justify-content-end" >
            <button class="btn registerbtn" >Reset</button>
        </div>

    </div>
        

    </div>

    <div class="row footer">
        <div class="div" style="margin-right: 2.96875vw;
        display: flex;
        flex-direction: row-reverse;">Copyright ©2024 iCodex Solutions Pvt.limited. All Rights Reserved.</div>
    </div> 
</div>
