import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { ForgotComponent } from './forgot/forgot.component';
import { RouterModule, Routes, RouterOutlet } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationComponent } from './authentication.component';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
//import { HttpLoaderFactory} from 'src/app/app.module';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FooterComponent } from 'src/app/core/footer/footer.component';
import { CoreModule } from 'src/app/core/core.module';
import { AuthFooterComponent } from './auth-footer/auth-footer.component';
import { NgSelectModule } from '@ng-select/ng-select';

// AoT requires an exported function for factories
// export function HttpLoaderFactory(httpClient: HttpClient) {
//   return new TranslateHttpLoader(httpClient);
// }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const routes: Routes = [{
  path: '',
  component: AuthenticationComponent,
  children:[
    {
      path:'',
      component: AuthenticationComponent
    },
    {
      path:'login',
      component: AuthenticationComponent
    },
   
  ]
}];

@NgModule({
  declarations: [ AuthenticationComponent, AuthFooterComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ]
})
export class AuthenticationModule { }
