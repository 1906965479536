<div class="main">

    <!-- ---------------------------------------------------------- -->

    <div class="row" style="height: 5.146875vw;border-bottom:0.10416666666666667vw solid #F5F6F8;width: 100vw;">
        <div class="div" style="display: flex;">
            <div class=" " style="align-items: center;display: flex;width: 14.739583333333334vw;
              background-color: #FFFFFF;justify-content: center;height: 5.146354166666667vw;border-bottom:0.10416666666666667vw solid #F5F6F8">
            <img src="../../../assets/Images/Icodex-logo.jpg" id="logo" [routerLink]="['/login']">
        </div>
            <div class="div heading1">
                Customer Quality Assurance
            </div>
        </div>
    </div>

    <div class="row card">
        <div class="div">
        <div class="row welcome">Welcome !</div>
        <div class="row signin">Sign in to continue to Customer Quality Assurance</div>

        <form [formGroup]="loginform" (ngSubmit)="validateCaptcha1()">

        <div class="row" style="margin-left:0.9895833333333334vw;margin-top: 0.8838541666666667vw;">
            <div class="text-title">Username</div>
            <div class="input-text"><input type="text" value="" placeholder="Enter username" formControlName="Username" class="form-control"></div>
        </div>

        <div class="row" style="margin-left:0.9895833333333334vw;margin-top: 0.8578125vw;">
            <div class="text-title" style="display: flex;">Password <div class="div forgotpass" [routerLink]="['']">Forgot password?</div></div>
            <div class="input-text"><input type="password" value="" placeholder="Enter Password" formControlName="Password" class="form-control"></div>
        </div>

    </form>

        <div class="card captchaCol d-flex justify-content-center" style="width: 8.958333333333334vw !important;
        height: 2.011979166666667vw !important;margin-top: 1.1598958333333333vw;box-shadow: none;">
            <input type="" class="blur noselect" id="txtCaptcha1" onmousedown="return false"
                onselectstart='return false;' />
        </div>

        <div class="row" style="margin-left:0.9895833333333334vw;margin-top: -0.5223958333333333vw">
            <div class="text-title">Type the words above</div>
            <div class="input-text"><input type="text" value="" id="txtCompare1" required placeholder="Captcha" class="form-control"></div>
        </div>

        <div class="col d-flex justify-content-end" >
            <button class="btn registerbtn" aria-pressed="true"(click)="validateCaptcha1()" >Log In</button>
        </div>

        <div class="row signinwith">Sign in with</div>

        <div class="row" style="justify-content: center;">
            <img src="../../../assets/Images/Group 1676.png" class="logoGmail" style="padding: 0px;cursor: pointer;" >
        </div>

        <div class="row" style="margin-top: 1.3020833333333333vw;">
            <div class="div Alreadytext" style="display: flex;">Don't have an account ?
                <div class="div logintxt"[routerLink]="['/login']">&nbsp;Signup now</div>
            </div>
        </div>
        
    </div>
        

    </div>

    <div class="row footer">
        <div class="div" style="margin-right: 2.96875vw;
        display: flex;
        flex-direction: row-reverse;">Copyright ©2024 iCodex Solutions Pvt.limited. All Rights Reserved.</div>
    </div>


    <!-- ---------------------------------------------------------- -->

    <!-- <div class="row">
        <div class="col">
            <img class="logo" src="../../assets/Images/logo.png" alt="logo">
        </div>
        <div class="col-3 mt-5">
            <div class="d-flex justify-content-center pageHeader ">
                <h3><strong style="font-size: 1.4583333333333333vw;">Customer Quality Assurance</strong></h3>
            </div>
            <div class="content justify-content-center">
                <h3 id="header"><strong style="font-size: 1.4583333333333333vw;">Login1</strong></h3>
                <div class="">

                    <form [formGroup]="loginform" (ngSubmit)="validateCaptcha1()">
                    <div class="input-group  mt-4">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="leftLabel">Username :</span>
                        </div>
                        <input type="text" class="form-control"  formControlName="Username" id="rightLabel" aria-label="Username"
                            aria-describedby="basic-addon1">
                    </div>
                    <div class="input-group ">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="leftLabel">Password :</span>
                        </div>
                        <input type="password" class="form-control" formControlName="Password" id="rightLabel" aria-label="Password"
                            aria-describedby="basic-addon2">
                    </div>
                    </form>
                    <div class="card captchaCol d-flex justify-content-center" style="height: 2.5em;">
                        <input type="" class="blur noselect" id="txtCaptcha1" onmousedown="return false"
                            onselectstart='return false;' />
                    </div>
                    <div class="input-group ">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="leftLabel">Captcha :</span>
                        </div>
                        <input type="text" class="form-control" id="rightLabel" aria-label="Username"
                            aria-describedby="basic-addon1" id="txtCompare1" required>
                    </div>

                </div>
                <div class="row ">
                    <div class="col">
                        <a class="btn btn-primary btn-sm active" id="createBtn" role="button" aria-pressed="true"
                        (click)="validateCaptcha1()">Login</a>
                    </div>
                  <div class="col">
                    <a href="#" id="createBtn " class="forgot-text d-flex justify-content-end  " aria-pressed="true" >Forgot
                        Password?</a>
                  </div>
                   
                </div>


               
                <div class="col row p-0">
                    <div class="col">
                        <span class="line1"></span>
                    </div>
                    <div class="col-1" id="or">Or</div>
                    <div class="col  pr-0">
                        <span class="line2"></span>
                    </div>
                </div>
                <div class="icon d-flex justify-content-center ">
                    <img src="../../assets/Images/google-logo.png" alt="">&nbsp;&nbsp;&nbsp;
                    <img src="../../assets/Images/JB front and backArtboard 2.png" class="custom-h1" alt="">
                    <img src="../../assets/Images/linkedin.png" alt="">&nbsp;&nbsp;&nbsp;
                </div>
            </div>
        </div>
        <div class="col">

        </div>
    </div> -->
  </div>  