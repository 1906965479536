import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { SummaryComponent } from 'src/app/pages/summary/summary.component';
@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.css']
})
export class MenubarComponent implements OnInit {
 
  @ViewChild('sidenav') sidenav: any = MatSidenav;
  isExpanded = true;
  isTask:boolean=false;
  isArrow1:boolean=false;
  isDashboard:boolean=true;
  showSubmenu: boolean = false;
  isSummaryReport:boolean=false;
  isDetailsReport:boolean=false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  userName = '';
  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }
  constructor(private router: Router) { }

  ngOnInit(): void {
    let currentRoute = this.router;
    let currentUrl = currentRoute.url;
    this.isTask = ['/availabletask'].findIndex(str => currentUrl.includes(str)) == 0;
    this.isArrow1 = ['/dashboard'].findIndex(str => currentUrl.includes(str)) == 0;
    this.isDashboard = ['/dashboard'].findIndex(str => currentUrl.includes(str)) == 0;
    // this.isSummaryReport = ['/availabletask'].findIndex(str => currentUrl.includes(str)) === 0;
    const summaryUrls = ['/summary', '/vendorremark'];
      this.isSummaryReport = summaryUrls.some(str => currentUrl.includes(str));
      this.isDetailsReport = ['/detail'].findIndex(str => currentUrl.includes(str)) == 0;

   
   
  }

  getSummary(){
    setTimeout(() => {
      this.router.navigate(['detail'],);

    }, 1);
    setTimeout(() => {
        this.router.navigate(['summary'],);

    }, 2);
  }

 

}
