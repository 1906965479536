<div class="main">
    <!-- <div class="row">
        <div class="col">
            <img class="logo" src="../../assets/Images/logo.png" alt="logo">
        </div>
        <div class="col-3">
            <div class=" d-flex justify-content-center pageHeader">
                <h3 id="main-header"><strong>Content Service Platform</strong></h3>
            </div>
        
        <div class="content justify-content-center">
            <h3 id="header"><strong>Sign Up</strong></h3>
            <div class="mt-4">

                <div class="d-flex ">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="leftLabel">First Name :</span>
                        </div>
                        <input type="text" class="form-control" id="rightLabel" aria-label="Username"
                            aria-describedby="basic-addon1">
                    </div>&nbsp;&nbsp;
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="leftLabel">Last Name :</span>
                        </div>
                        <input type="text" class="form-control" id="rightLabel" aria-label="Username"
                            aria-describedby="basic-addon1">
                    </div>
                </div>
                <div class="input-group ">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="leftLabel">Username :</span>
                    </div>
                    <input type="text" class="form-control" id="rightLabel" aria-label="Username"
                        aria-describedby="basic-addon1">
                </div>
                <div class="input-group ">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="leftLabel">Email :</span>
                    </div>
                    <input type="text" class="form-control" id="rightLabel" aria-label="Username"
                        aria-describedby="basic-addon1">
                </div>
                <div class="input-group ">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="leftLabel">Password :</span>
                    </div>
                    <input type="text" class="form-control" id="rightLabel" aria-label="Username"
                        aria-describedby="basic-addon1">
                </div>
                <div class="input-group ">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="leftLabel">Confirm Password :</span>
                    </div>
                    <input type="text" class="form-control" id="rightLabel" aria-label="Username"
                        aria-describedby="basic-addon1">
                </div>


            </div>
            <div class="col row mb-4 ">
                <div class="col p-0">
                    <div class=""> <a [routerLink]="['/login']" class="btn btn-primary btn-sm active" id="createBtn"
                            role="button" aria-pressed="true">Create Account</a>
                    </div>
                </div>
                <div class="col d-flex justify-content-end p-0">
                    <span [routerLink]="['/login']"
                        style="color: rgb(13, 87, 156); cursor: pointer; color: #3155A6; padding: 0px; font-size:0.8333vw;">Existing
                        User ?</span>
                </div>
            </div>

            <div class="col row p-0 mb-3">
                <div class="col">
                    <span class="line1"></span>
                </div>
                <div class="col-1" id="or">Or</div>
                <div class="col  pr-0">
                    <span class="line2"></span>
                </div>
            </div>
            <div class="icon d-flex justify-content-center ">
                <img src="../../assets/Images/google-logo.png" alt="">&nbsp;&nbsp;&nbsp;
                <img src="../../assets/Images/JB front and backArtboard 2.png" class="custom-h1" alt="">
                <img src="../../assets/Images/linkedin.png" alt="">&nbsp;&nbsp;&nbsp;
            </div>
        </div>
    </div> -->
    
<!-- ---------------------------------------------------------- -->
    

    <div class="row" style="height: 5.146875vw;border-bottom:2px solid #F5F6F8;width: 100vw;">
        <div class="div" style="display: flex;">
            <div class=" " style="align-items: center;display: flex;width: 14.739583333333334vw;
              background-color: #FFFFFF;justify-content: center;height: 5.146354166666667vw;border-bottom:2px solid #F5F6F8">
            <img src="../../../assets/Images/Icodex-logo.jpg" id="logo" [routerLink]="['/login']">
        </div>
            <div class="div heading1">
                Customer Quality Assurance
            </div>
        </div>
    </div>

    <div class="row card">
        <div class="div">
        <div class="row welcome">Register Account</div>
        <div class="row signin">Sign in to continue to Customer Quality Assurance</div>
        <div class="row" style="margin-left:0.9895833333333334vw;margin-top: 1.71875vw;">
            <div class="text-title">First Name</div>
            <div class="input-text"><input type="text" value="" placeholder="Enter first name" class="form-control" aria-label="Username"
                aria-describedby="basic-addon1"></div>
        </div>

        <div class="row" style="margin-left:0.9895833333333334vw;margin-top: 0.8838541666666667vw;">
            <div class="text-title">Last Name</div>
            <div class="input-text"><input type="text" value="" placeholder="Enter last name" class="form-control"></div>
        </div>

        <div class="row" style="margin-left:0.9895833333333334vw;margin-top: 0.8838541666666667vw;">
            <div class="text-title">Username</div>
            <div class="input-text"><input type="text" value="" placeholder="Enter username" class="form-control"></div>
        </div>

        <div class="row" style="margin-left:0.9895833333333334vw;margin-top: 0.8838541666666667vw;">
            <div class="text-title">Email</div>
            <div class="input-text"><input type="text" value="" placeholder="Enter email" class="form-control"></div>
        </div>

        <div class="row" style="margin-left:0.9895833333333334vw;margin-top: 0.8838541666666667vw;">
            <div class="text-title">Password</div>
            <div class="input-text"><input type="text" value="" placeholder="Enter Password" class="form-control"></div>
        </div>

        <div class="row" style="margin-left:0.9895833333333334vw;margin-top: 0.8838541666666667vw;">
            <div class="text-title">Confirm Password</div>
            <div class="input-text"><input type="text" value="" placeholder="Confirm Password" class="form-control"></div>
        </div>

        <div class="col d-flex justify-content-end" >
            <button class="btn registerbtn" [routerLink]="['/login']" >Register</button>
        </div>

        <div class="row signinwith">Sign in with</div>

        <div class="row" style="justify-content: center;">
            <img src="../../../assets/Images/Group 1676.png" class="logoGmail" style="padding: 0px;cursor: pointer;" >
        </div>

        <div class="row" style="margin-top: 0.2604166666666667vw;">
            <div class="div Alreadytext" style="display: flex;">Already have an account ?
                <div class="div logintxt"[routerLink]="['/login']">&nbsp;Login</div>
            </div>
        </div>

        




        
    </div>
        

    </div> 

    <div class="row footer">
        <div class="div" style="margin-right: 2.96875vw;
        display: flex;
        flex-direction: row-reverse;">Copyright ©2024 iCodex Solutions Pvt.limited. All Rights Reserved.</div>
    </div>
    


</div>