import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.css']
})
export class RightPanelComponent implements OnInit {

  constructor() { }
  isInatke:boolean=true;
  isCompletenesCheck:boolean=false;
  isAuthorQuires:boolean=false;
  isFinalReview:boolean=false;
  isFinal:boolean=false;
  subDeptId:any;
  Departmentid:any;
  ngOnInit(): void {
    this.Departmentid = sessionStorage.getItem('DeptId');
    this.subDeptId= sessionStorage.getItem('subdeptId')
    this.getDepartment();


  }

  
  getDepartment() {

    if (this.Departmentid == 2 || this.Departmentid==1) {
      this.isInatke = true;
    }
    else if (this.Departmentid == 3) {
      this.isCompletenesCheck = true;
    }
    else if (this.Departmentid == 4) {
      this.isAuthorQuires = true;
    }
    else if (this.Departmentid == 5) {
      this.isFinalReview = true;
    }
    else if(this.subDeptId==7){
      this.isFinal=true;
    }
  }

}
