import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ServicePaths } from 'src/app/shared/service-paths';
import { ApiService } from '../services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {


  loginform: FormGroup;
  constructor(private servicePaths: ServicePaths, private router: Router, private apiService: ApiService, private fb: FormBuilder, private _router: Router,private toastr :ToastrService) { }



  ngOnInit(): void {

    this.GenerateCaptcha1();
    this.loginform = this.fb.group({
      Username: ['', Validators.required],
      Password: ['', Validators.required]
    })


  }
  public GenerateCaptcha1() {
    var chr1 = Math.ceil(Math.random() * 10) + '';
    var chr2 = Math.ceil(Math.random() * 10) + '';
    var chr3 = Math.ceil(Math.random() * 10) + '';
    var str = new Array(4).join().replace(/(.|$)/g, function () { return ((Math.random() * 36) | 0).toString(36)[Math.random() < .5 ? "toString" : "toUpperCase"](); });
    var captchaCode = str + chr1 + chr2 + chr3;
    const txtCaptcha = document.getElementById("txtCaptcha1") as HTMLInputElement;
    txtCaptcha.value = captchaCode
  }


  stripHtml(captcha: any) {
    let tmp = document.createElement("DIV");
  }

  public validateCaptcha1() {
    debugger

    var str1 = document.getElementById('txtCaptcha1') as HTMLInputElement;
    var str2 = document.getElementById('txtCompare1') as HTMLInputElement;
    if (str1.value == str2.value) {
      this.getLogin();
    }
    else {
      // this.login = 'false';
      alert('Wrong Captcha code')
      this.GenerateCaptcha1();
      return false;
    }

  }


  public getLogin() {
    debugger
    let requestobject: object = {
      "UserName": this.loginform.value.Username,
      "Password": this.loginform.value.Password,
    }
    console.log(requestobject)
    this.apiService.getLogin(requestobject).subscribe(
      d => {
        debugger
        console.log(d)
        if(d.FirstName==null){
          this.toastr.warning('', 'Enter valid credentials', {
            timeOut: 3000,
          });
        }
        else{
          sessionStorage.setItem('FirstName', d.FirstName)
          sessionStorage.setItem('LastName', d.LastName)
          sessionStorage.setItem('VendorId', d.VendorId)
          this.router.navigate(['/dashboard']);
        }


      }, e => {
        console.log(e)
        this.toastr.error('', 'Login Failed', {
          timeOut: 3000,
        });
        this.GenerateCaptcha1();
      }
    )
  }


}
