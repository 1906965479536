import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ServicePaths } from 'src/app/shared/service-paths';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {

  public resetForm: FormGroup;
  public message: string;
  public invalidInput: boolean = false;
  public loginBtnLoader: boolean = false;
  public UserName: string = sessionStorage.getItem('UserName');
  public resetParameter:string ="";
  public decodedValue:any = [];
  public compare: boolean =null;
  public isParameter:boolean = true;

  constructor(private servicePaths: ServicePaths, private formBuilder: FormBuilder, private _router: Router,
    private activatedRoute:ActivatedRoute,public translate: TranslateService) { 
      this.translate.use(sessionStorage.getItem('lang')); }

  ngOnInit(): void {
    this.resetForm = this.formBuilder.group({
      password1: ["", Validators.required],
      password2: ["", Validators.required]
    });
    this.resetParameter = this.activatedRoute.snapshot.queryParamMap.keys[0];
    if(this.resetParameter){
      this.message=""
      this.isParameter = true;
      this.decodedValue = atob(this.resetParameter).split("#")
    }else{
      this.message="No parameter found"
      this.isParameter = false;
      this._router.navigate(['/']);
    }
  }

  public focusIn(e) {
    this.message = "";
    this.invalidInput = false;
  }

  get f() { return this.resetForm.controls; }

  

  comparepass(){
  
    let NewPassword = this.f.newpassword.value;
    let renewpassword = this.f.renewpassword.value;
    // console.log(renewpassword)

    if(renewpassword=="")
    {
      this.compare=null;
    }
    else{
      if(NewPassword==renewpassword)
      {
       this.compare=true;
      //  console.log(NewPassword)
      //  console.log(this.compare)
      }
      else{
        this.compare=false;
        // console.log(NewPassword)
        // console.log(this.compare)
      }
    }
    
    
  }

  comparepass1(){
    let NewPassword = this.f.password1.value;
    if(!NewPassword)
    {
     this.compare=null;
    }
  }

}
